import axios from 'axios';
// import appConfig from 'configs/app.config'
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from 'constants/api.constant';
import { PERSIST_STORE_NAME } from 'constants/app.constant';
import deepParseJson from 'utils/deepParseJson';
import store from '../store';
import { setUser, initialState } from '../store/auth/userSlice';
import { onSignOutSuccess } from '../store/auth/sessionSlice';
import { setError } from 'store/theme/themeSlice';
import convertEmptyKeysToNull from 'utils/parseEmptyToNull';
const unauthorizedCode = [401];
const API_URL = process.env.REACT_APP_BASEURL;

const BaseService = axios.create({
  baseURL: API_URL,
});

BaseService.interceptors.request.use(
  (config) => {
    const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
    const persistData = deepParseJson(rawPersistData);
    if (config.data) config.data = convertEmptyKeysToNull(config.data);
    const { token, expiration } = persistData.auth.session;

    if (expiration && new Date(expiration.toString()) <= new Date()) {
      store.dispatch(onSignOutSuccess());
      store.dispatch(setUser(initialState));

      if (token !== '') {
        window.location.reload();
      }
    }

    if (token) {
      config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

BaseService.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 500) {
      store.dispatch(
        setError({ Title: 'Gabim!', Status: 4, Description: 'Ka ndodhur një gabim gjatë procesimit të të dhënave!' })
      );
    } else if (error.response?.status === 401) {
      store.dispatch(
        setError({
          Title: 'Qasje e paautorizuar!',
          Status: 3,
          Description:
            'Ju nuk jeni i autorizuar të qaseni në këtë burim. Ju lutem respektoni privatësinë e të tjerëve!',
        })
      );
    }
    return error;
  }
);

export default BaseService;
