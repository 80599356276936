import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  avatar: '',
  userName: '',
  email: '',
  authority: [],
  firstname: '',
  lastname: '',
  userId: '',
  language: '',
};

export const userSlice = createSlice({
  name: 'auth/user',
  initialState,
  reducers: {
    setUser: (_, action) => action.payload,
    updateUserLanguage: (state, action) => {
      state.language = action.payload;
    },
    userLoggedOut: () => initialState,
    setAuthority: (state,action) => {
      state.authority = action.payload;
  },
}
});

export const { setUser, updateUserLanguage , setAuthority} = userSlice.actions;

export default userSlice.reducer;
