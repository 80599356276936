import ApiService from './ApiService';

export async function apiSignIn(data) {
  return ApiService.fetchData({
    url: '/Authentication',
    method: 'post',
    data,
  });
}

export async function apiSignResetPassword(data) {
  return ApiService.fetchData({
    url: '/Administration/ResetPassword',
    method: 'post',
    data,
  });
}

export async function apiSignOut(data) {
  return ApiService.fetchData({
    url: '/Administration/LogOut',
    method: 'post',
    data,
  });
}

export async function apiForgotPassword(data) {
  return ApiService.fetchData({
    url: `/Authentication/ResetPasswordEmail?email=` + data,
    method: 'post',
    data,
  });
}

export async function apiResetPassword(data) {
  return ApiService.fetchData({
    url: '/Authentication/ResetPassword',
    method: 'post',
    data,
  });
}

export async function apiRegisterUser(data) {
  return ApiService.fetchData({
    url: '/User',
    method: 'post',
    data,
  });
}

export async function apiRefreshUser() {
  return ApiService.fetchData({
    url: '/Authentication/RefreshUser',
    method: 'get',
  });
}

export async function apiConfirmAccount(id, token) {
  return ApiService.fetchData({
    url: `/User/${id}/ConfirmAccount`,
    method: 'put',
    data: JSON.stringify(token),
    params: { token: token },
  });
}

export async function apiChangePasswordFromUser(data) {
  return ApiService.fetchData({
    url: '/Authentication/ChangePasswordFromUser',
    method: 'post',
    data: data,
  });
}
