import ApiService from "./ApiService"

export async function apiGetMenus (data) {
    return ApiService.fetchData({
        url: '/Home/GetSysMenu',
        method: 'get',
        data
    })
}

export async function apiGetAllParentMenu (data) {
    return ApiService.fetchData({
        url: '/SysMenu/GetAllParentMenu',
        method: 'get',
        data
    })
}
