import React, { memo, useMemo, lazy, Suspense } from 'react';
import { Loading } from 'components/shared';
import { useDispatch, useSelector } from 'react-redux';
import {
  LAYOUT_TYPE_CLASSIC,
  LAYOUT_TYPE_MODERN,
  LAYOUT_TYPE_SIMPLE,
  LAYOUT_TYPE_STACKED_SIDE,
  LAYOUT_TYPE_DECKED,
  LAYOUT_TYPE_BLANK,
} from 'constants/theme.constant';
import useAuth from 'utils/hooks/useAuth';
import useDirection from 'utils/hooks/useDirection';
import useLocale from 'utils/hooks/useLocale';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import WebsiteLoader from 'components/shared/WebsiteLoader';
import { Alert, Notification, toast } from 'components/ui';
import { setError, setLayout, setMode, setNavMode, setThemeColor, setThemeColorLevel } from 'store/theme/themeSlice';
import { apiRefreshUser } from 'services/AuthService';
import { onSignInSuccess } from 'store/auth/sessionSlice';
import { setUser } from 'store/auth/userSlice';
import { getMenus } from 'configs/navigation.config/store/dataSlice';
import { apiGetThemeConfig } from 'services/HomeServices';

const layouts = {
  [LAYOUT_TYPE_CLASSIC]: lazy(() => import('./ClassicLayout')),
  [LAYOUT_TYPE_MODERN]: lazy(() => import('./ModernLayout')),
  [LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import('./StackedSideLayout')),
  [LAYOUT_TYPE_SIMPLE]: lazy(() => import('./SimpleLayout')),
  [LAYOUT_TYPE_DECKED]: lazy(() => import('./DeckedLayout')),
  [LAYOUT_TYPE_BLANK]: lazy(() => import('./BlankLayout')),
};

const Layout = () => {
  const dispatch = useDispatch();
  const layoutType = useSelector((state) => state.theme.layout.type);
  // const settingsData = useSelector((state) => state.SettingsEdit.data.settings);
  var error = useSelector((state) => state.theme.error);
  const { token } = useSelector((state) => state.auth.session);

  const { language } = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (token) {
      apiRefreshUser().then((res) => {
        if (res && res.data) {
          dispatch(onSignInSuccess({ token: res.data.token, expiration: res.data.expiration }));
          dispatch(
            setUser(
              res.data.user || {
                avatar: '',
                userName: 'Anonymous',
                authority: ['USER'],
                email: '',
                firstname: '',
                lastname: '',
                userId: '',
              }
            )
          );
        }
      });
    }
  }, []);

  const { authenticated } = useAuth();

  useEffect(() => {
    if (authenticated == true) {
      dispatch(getMenus());
      // apiGetThemeConfig().then((response) => {
      //   dispatch(setMode(response?.data?.theme?.mode));
      //   dispatch(setLayout(response?.data?.theme?.layoutType));
      //   dispatch(setNavMode(response?.data?.theme?.navMode));
      //   dispatch(setThemeColor(response?.data?.theme?.themeColor));
      //   dispatch(setThemeColorLevel(response?.data?.theme?.primaryColorLevel));
      // });
    }
  }, [authenticated, language]);

  useDirection();
  const location = useLocation();
  useLocale();

  const AppLayout = useMemo(() => {
    if (authenticated && location.pathname.includes('/')) {
      return layouts[layoutType];
    }
    return lazy(() => import('./AuthLayout'));
  }, [layoutType, authenticated]);

  const loadingElement = location.pathname.includes('/') ? (
    <div className="flex flex-auto flex-col h-[100vh]">
      <Loading loading={true} />
    </div>
  ) : (
    <div className="flex flex-auto flex-col h-[100vh] justify-center items-center">
      <WebsiteLoader />
    </div>
  );

  useEffect(() => {
    if (error) {
      const toastNotification = (
        <Notification closable title={error.Title} type="danger" duration={7000}>
          {error.Description}
        </Notification>
      );
      toast.push(toastNotification);
      dispatch(setError(undefined));
    }
  }, [error]);

  return (
    <Suspense fallback={loadingElement}>
      <AppLayout />
    </Suspense>
  );
};

export default memo(Layout);
