import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiGetMenus } from 'services/SysMenuServices';

export const getMenus = createAsyncThunk('menuList/data/getMenuList', async (data) => {
  try {
    const response = await apiGetMenus(data);
    if (!response || !response.data || !Array.isArray(response.data)) {
      throw new Error('Invalid response format');
    }
    return response.data;
  } catch (error) {
    console.error('Error fetching menus:', error);
    throw error;
  }
});

const dataSlice = createSlice({
  name: 'menuList/data',
  initialState: {
    loading: false,
    menuList: [], // Initialize with an empty array
  },
  reducers: {
    updateMenuList: (state, action) => {
      state.menuList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMenus.fulfilled, (state, action) => {
      state.menuList = action.payload;
    });
  },
});

export const { updateMenuList } = dataSlice.actions;

export default dataSlice.reducer;
