export default function convertEmptyKeysToNull(obj) {
  // Check if the value is an object and not null, and that it isn't an array
  if (typeof obj === 'object' && obj !== null) {
    // If the object is an array, iterate through its elements
    if (Array.isArray(obj)) {
      obj.forEach((item, index) => {
        // If the item is an empty string, convert it to null
        if (item === '') {
          obj[index] = null;
        } else if (typeof item === 'object' && item !== null) {
          // If the item is an object or array, recursively process it
          convertEmptyKeysToNull(item);
        }
      });
    } else {
      // For non-array objects, iterate through the keys
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];

          // If the value is an empty string, convert it to null
          if (value === '') {
            obj[key] = null;
          }

          // If the value is an object or array, recursively process it
          if (typeof value === 'object' && value !== null) {
            convertEmptyKeysToNull(value);
          }
        }
      }
    }
  }
  return obj;
}
