import React from 'react'
import { FaImage } from 'react-icons/fa6'
import { VscFilePdf, VscFileZip, VscFile } from 'react-icons/vsc'

const BYTE = 1000
const getKB = (bytes) => Math.round(bytes / BYTE)

const FileIcon = ({children}) => {
	return <span className="text-4xl">{children}</span>
}

const FileItem = props => {

	const { file, children, onClick  } = props
	const { type, name, size } = file

	const renderThumbnail = () => {
		const isImageFile = type.split('/')[0] === 'image'

		if(isImageFile) {
			return <FileIcon><FaImage /></FileIcon>

		}

		if(type === 'application/zip') {
			return <FileIcon><VscFileZip /></FileIcon>
		}

		if(type === 'application/pdf') {
			return <FileIcon><VscFilePdf /></FileIcon>
		}

		return <FileIcon><VscFile /></FileIcon>
	}
	return (
		<div className="upload-file" onClick={onClick} style={{ cursor: 'pointer' }}>
			<div className="flex">
				<div className="upload-file-thumbnail">
					{renderThumbnail()}
				</div>
				<div className="upload-file-info">
					<h6 className="upload-file-name">{name}</h6>
				</div>
			</div>
			{children}
		</div>
	)
}

export default FileItem
